
import { reactive, toRefs, onBeforeMount, onMounted } from 'vue'
import { Image as VanImage } from 'vant'
import 'amfe-flexible'

export default {
  name: '',
  components: { [VanImage.name]: VanImage },
  setup() {
    console.log('1-开始创建组件-setup')
    const data = reactive({
      content: require('./assets/content.png')
    })
    onBeforeMount(() => {
      console.log('2.组件挂载页面之前执行----onBeforeMount')
    })
    onMounted(() => {
      console.log('3.-组件挂载到页面之后执行-------onMounted')
    })
    return {
      ...toRefs(data)
    }
  }
}
